import axios from 'axios';

const fixtures = axios.create({
    baseURL: process.env.VUE_APP_BASE_FIXTURE_URL,
});

fixtures.defaults.headers.post['Content-Type'] = 'application/json';
fixtures.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

fixtures.defaults.method = "get";

export default fixtures;